<template>
  <sheet depressed dense light outlined>
    <v-row>
      <v-col cols="12">
        <invoice-labels-dialog v-if="!hideLabels" field="Adenda" />
        <invoice-addenda v-model="obInvoice" :read-only="readOnly" />
      </v-col>
    </v-row>
  </sheet>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";
import InvoiceAddenda from "@/modules/invoices/components/InvoiceAddenda.vue";
import InvoiceLabelsDialog from "@/modules/invoices/components/InvoiceLabelsDialog.vue";

@Component({
  components: { InvoiceLabelsDialog, InvoiceAddenda },
})
export default class InvoiceRowAddenda extends Mixins(InvoiceMixin) {
  @Prop(Boolean) readonly readOnly!: boolean;
  @Prop(Boolean) readonly hideLabels!: boolean;
}
</script>
