<template>
  <ValidationProvider :name="$t(sLabel)" :rules="sRules" :vid="sVid" slim>
    <template #default="{ errors, valid }">
      <v-select
        v-model="sField"
        :disabled="disabled"
        :error-messages="errors"
        :items="items"
        :success="required ? valid : undefined"
        v-bind="obAttrs"
        @change="onSelect"
      />
    </template>
  </ValidationProvider>
</template>

<script lang="ts">
import { Component, Mixins, Prop, VModel } from "vue-property-decorator";
import { LabelsModule } from "@/store/labels";
import { camelCase } from "lodash";
import SelectFieldMixin from "@/mixins/SelectFieldMixin";

@Component
export default class LabelFieldSelect extends Mixins(SelectFieldMixin) {
  @VModel({ type: String }) sField!: string;
  @Prop(Boolean) readonly disabled!: boolean;

  sLabelDefault: string = "field";

  get items() {
    return LabelsModule.items;
  }

  get fieldOptions() {
    return LabelsModule.fieldOptions;
  }

  get sRules() {
    let sRules = "";
    if (this.required && !sRules.includes("required")) {
      sRules = sRules.length ? `required|${sRules}` : "required";
    }
    return sRules;
  }

  get sVid() {
    return camelCase(this.label);
  }

  onSelect(sValue: string) {
    this.$emit("input", sValue);
    this.$emit("change", sValue);
  }

  async mounted() {
    if (!this.fieldOptions) {
      await LabelsModule.loadFields();
    }
  }
}
</script>
