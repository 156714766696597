<template>
  <component :is="tag" v-html="sText" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Nl2br extends Vue {
  @Prop({ type: String, required: true }) readonly value!: string;
  @Prop({ type: String, default: () => "p" }) readonly tag!: string;

  get sText() {
    return this.value
      .split("\n")
      .reduce((accumulator: string[], sValue: string) => {
        if (accumulator.length === 0) {
          return new Array(sValue);
        }
        return accumulator.concat(["<br />", sValue]);
      }, [])
      .join("");
  }
}
</script>
