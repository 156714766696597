<template>
  <v-list v-auto-animate dense flat>
    <company-label-item
      v-for="obLabel in items"
      :key="`label.${obLabel.id}.${obLabel.field}`"
      :hide-delete="hideDelete"
      :hide-update="hideUpdate"
      :item="obLabel"
      :show-apply="showApply"
      :show-copy="showCopy"
      @copy="onCopy"
      @delete="onRemove"
      @update="onSelect"
    />
  </v-list>
</template>

<script lang="ts">
import type { Label } from "@planetadeleste/vue-mc-gw";
import { Component, Prop, Vue } from "vue-property-decorator";
import CompanyLabelItem from "@/modules/companies/components/CompanyLabelItem.vue";

@Component({
  components: { CompanyLabelItem },
})
export default class CompanyLabelList extends Vue {
  @Prop([Array, Object]) readonly items!: Label[];
  @Prop(Boolean) readonly hideDelete!: boolean;
  @Prop(Boolean) readonly hideUpdate!: boolean;
  @Prop(Boolean) readonly showCopy!: boolean;
  @Prop(Boolean) readonly showApply!: boolean;

  onSelect(obModel: Label) {
    this.$emit("update", obModel);
  }

  onCopy(obModel: Label) {
    this.$emit("copy", obModel);
  }

  onRemove(obModel: Label) {
    this.$emit("delete", obModel);
  }
}
</script>
